import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import NavbarWrapper from "reusecore/src/elements/Navbar";
import Drawer from "reusecore/src/elements/Drawer";
import Logo from "reusecore/src/elements/UI/Logo";
import Box from "reusecore/src/elements/Box";
import HamburgMenu from "../../components/HamburgMenu";
import Container from "../../components/Container";
import VisualEyesLogo from "../../components/VisualEyesLogo";
// import VisualEyesLogo from "../../components/ChristmasLogo";
import { DrawerContext } from "../../contexts/DrawerContext";
import ScrollSpyMenu from "../../components/ScrollSpyMenu";
import menuItems from "./data";
import NeuronsBanner from "../../components/NeuronsBanner";

const Navbar = ({ navbarStyle, logoStyle, row, menuWrapper }) => {
  const { state, dispatch } = useContext(DrawerContext);

  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);

  function handleScroll() {
    const nextScrollPos = Math.abs(document.body.getBoundingClientRect().top);
    setTimeout(() => setScrollPos(nextScrollPos), 300);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const minHeight = 100;
    // Math.max(
    //   document.documentElement.clientHeight,
    //   window.innerHeight || 0
    // );

    const nextScrollPos = Math.abs(document.body.getBoundingClientRect().top);

    const isBelowOffset = window.scrollY > minHeight;
    const isScrollingDown = nextScrollPos >= scrollPos;
    const isIdle = nextScrollPos === scrollPos;

    if (isIdle) {
      setTimeout(() => setIsIdle(true), 2000);
    } else {
      setIsIdle(false);

      setIsNavHidden(isScrollingDown && isBelowOffset);
      setHasScrolled(isBelowOffset);
    }
    // setIsNavHidden(isScrollingDown && isBelowOffset);
  }, [scrollPos]);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  const navBarStyle = getNavBarStyle(isNavHidden, isIdle, hasScrolled);

  return (
    <NavbarWrapper {...navbarStyle} style={navBarStyle}>
      <NeuronsBanner />
      <Container>
        <Box {...row}>
          <Link to="/">
            <VisualEyesLogo width={130} />
          </Link>

          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menuItems}
              offset={-170}
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#3E21DE" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    className: "hosting_navbar",
    minHeight: "140px",
    display: "block",
  },
  row: {
    flexBox: true,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  logoStyle: {
    maxWidth: "130px",
    mr: [0, 0, 0, "100px"],
  },
  button: {
    type: "button",
    fontSize: "13px",
    fontWeight: "600",
    color: "white",
    borderRadius: "4px",
    pl: "16px",
    pr: "16px",
    colors: "primaryWithBg",
    minHeight: "auto",
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: "center",
  },
};

export default Navbar;
function getNavBarStyle(isNavHidden, isIdle, hasScrolled) {
  return (isNavHidden || isIdle) && hasScrolled
    ? {
        transition: "all .5s ease-in-out",
        // display: "none",
        top: -140,
      }
    : {
        transition: "all .5s ease-in-out",
        // display: "block",
        top: 0,
      };
}
