import React from "react";

export const NeuronsBanner = ({ isFixed = false, ...rest }) => {
  return (
    <a
      href="https://www.neuronsinc.com/predict"
      target="_blank"
      rel="noreferrer"
      style={{
        width: "100%",
        minWidth: "100%",
        minHeight: "45px",
        backgroundColor: "#3e21de",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        padding: "0.5rem 2rem",
        textAlign: "center",
        marginBottom: "20px",
        ...(isFixed ? { position: "fixed" } : {}),
        ...rest,
      }}
    >
      ✨ VisualEyes is now part of Neurons, the leading neuroscience
      company!&nbsp;&nbsp;&nbsp;→
    </a>
  );
};

export default NeuronsBanner;
