import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { Link } from "gatsby";
import { FiX } from "react-icons/fi";
import { useCookies } from "react-cookie";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 1rem auto;
  border-radius: 4px;
  border: 1px solid ${themeGet("colors.lightBorder", "#f1f4f6")};
  padding: 0.5rem;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.3);
  background-color: ${themeGet("colors.white", "#ffffff")};
  z-index: 1000;
  min-width: 300px;

  & > *:first-child {
    font-size: 16px;
    margin: 0;
    margin-right: 1rem;
  }
`;

const PolicyLabel = () => {
  const [cookies, setCookie] = useCookies(["policy"]);

  const [isAccepted, setIsAccepted] = useState(true);

  useEffect(() => {
    if (cookies.policy) {
      // console.log("exists");
    } else {
      setIsAccepted(false);

      // console.log("dont exists");

      setCookie("policy", 1);
    }
  }, []);

  function initCookie() {
    setCookie("policy", 1);
    setIsAccepted(true);
  }

  if (isAccepted) return null;

  return (
    <Container>
      <p>
        By using this site, you agree to our{" "}
        <a
          target="_blank"
          rel="noreferrer"
          style={{ color: "#3E21DE" }}
          href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf"
        >
          Privacy policy
        </a>
        .
      </p>{" "}
      <FiX onClick={initCookie} style={{ cursor: "pointer" }} />
    </Container>
  );
};

export default PolicyLabel;
