import { APP_SIGN_UP_URL, APP_LOGIN_URL } from "../../utils/constants";

const OFFSET = 200;

export default [
  {
    label: "How It Works",
    path: "/how",
    offset: OFFSET,
  },
  {
    label: "Features",
    path: "/features",
    offset: OFFSET,
  },
  // {
  //   label: "Learn",
  //   path:
  //     "https://www.notion.so/visualeyes/VisualEyes-Cheatsheet-9c30a263a02b4402ac841396b03bd382",
  //   offset: OFFSET
  // },
  // {
  //   label: "Pricing",
  //   path: "/pricing",
  //   offset: OFFSET,
  // },
  // {
  //   label: "Roadmap",
  //   path: "/roadmap",
  //   offset: OFFSET
  // },
  {
    label: "Use Cases",
    path: "/tools/use-cases",
    offset: OFFSET,
  },
  // {
  //   label: "Log In",
  //   path: APP_LOGIN_URL,
  //   offset: OFFSET,
  // },
  {
    label: "GET STARTED",
    path: APP_SIGN_UP_URL,
    offset: OFFSET,
    isCTA: true,
  },
];
