import React from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "reusecore/src/elements/Button";
import { fbTrack } from "../../utils/analytics";
import redirect from "../../utils/redirect";

export function isPricingPage() {
  return typeof window !== "undefined"
    ? window.location.pathname === "/pricing"
    : false;
}
export function moveToPlatform(menu) {
  return (
    <span
      onClick={() => {
        fbTrack("track", "Lead");
        redirect(menu.path, "Nav bar CTA");
      }}
    >
      <Button id="navbar-create-account" title={menu.label} />
    </span>
  );
}

export function renderCTA(menu, isHidden, drawerClose) {
  const pricingCTA = isHidden ? (
    <div style={{ minWidth: "12rem" }} />
  ) : (
    <AnchorLink href={"#pricing"} offset={menu.offset}>
      <Button
        id="pricing-page-cta-button"
        title={menu.label}
        style={{
          cursor: isHidden ? "none" : "pointer",
        }}
      />
    </AnchorLink>
  );

  const otherCTA = isHidden ? (
    <div style={{ minWidth: "12rem" }} />
  ) : (
    <Link to="/pricing" id="cta-button">
      <Button
        id="cta-button"
        title={menu.label}
        style={
          {
            // marginLeft: drawerClose ? "0" : "4rem",
            // opacity: isHidden ? 0 : 1
          }
        }
      />
    </Link>
  );

  return isPricingPage() ? pricingCTA : otherCTA;
}

function isLink(str) {
  return str[0] === "/";
}

export function renderLinkOrAnchor(menu) {
  return isLink(menu.path) ? (
    <Link
      to={menu.path}
      style={{ fontSize: "20px", fontWeight: "500" }}
      id={`navbar-${menu.label.split(" ").join("-")}`}
    >
      {menu.label}
    </Link>
  ) : (
    <a
      id={`navbar-${menu.label.split(" ").join("-")}`}
      href={menu.path}
      target="__blank"
      rel="noreferrer"
      style={{
        fontSize: "20px",
        fontWeight: "500",
        color: "#3E21DE",
      }}
    >
      {menu.label}
    </a>
  );
}
